<template>
  <div class="invitation-step__wrapper">
    <h4 v-show="titleShow">{{$t('invitation.titleStep')}}</h4>
    <div class="invitation-step">
      <div class="row">
        <div class="col-xs-4 step-item">
          <p><b>1</b></p>
          <p class="text-small">{{$t('invitation.labelStepOne')}}</p>
        </div>
        <div class="col-xs-4 step-item">
          <p><b>2</b></p>
          <p class="text-small">{{$t('invitation.labelStepTwo')}}</p>
        </div>
        <div class="col-xs-4 step-item">
          <p><b>3</b></p>
          <p class="text-small">{{showPaid ? '粉絲下單，' : '邀請好友，'}}獲得{{reward}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    titleShow: {
      type: Boolean,
      default: true
    },
    showPaid: {
      type: Boolean,
      default: true
    },
    reward: {
      type: String,
      default: '現金獎勵'
    }
  },
  data: () => ({
  }),
  computed: {},
  created () {},
  methods: {}
}
</script>

<style lang="scss">
  .invitation-step__wrapper {
    background-color: #fff;
    border-radius: 4px;
    padding: 16px 12px;
  }
  .invitation-step  {
    position: relative;
    margin: 1.5rem 0 0;
    .step-item {
      padding: 0;
    }
    .step-item > p:first-child {
      font-weight: 600;
      position: relative;
      b {
        position: relative;
        z-index: 3;
        display: inline-block;
        height: 2rem;
        width: 2rem;
        text-align: center;
        line-height: 2rem;
        background: #ffe3e3;
        border-radius: 100%;
        color: #f56368;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0%;
        right: 0%;
        top: 50%;
        margin-top: -1px;
        height: 2px;
        background-color: #ffe3e3;
      }
    }
    .step-item > p:last-child {
      padding: .75rem;
    }
    .step-item:first-child > p:first-child {
      &::before {
        left: 50%;
      }
    }
    .step-item:last-child > p:first-child {
      &::before {
        right:50%;
      }
    }
  }
</style>
